<template>
  <div>
    <b-card
      title="เจ้าหน้าที่"
      no-body
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="4"
            md="6"
          >
            <b-button
              variant="primary"
              :to="{ name: 'staff-create-new'}"
            >
              <span class="text-nowrap">เพิ่มเจ้าหน้าที่</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="8"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="ค้นหา..."
              />
              <b-button
                variant="primary"
                @click="submitSearch"
              >
                <span class="text-nowrap">ค้นหา</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refStaffListTable"
        class="position-relative"
        :items="fetchStaffs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'staff-edit', params: { staffId: data.item._id } }"
              />
            </template>
            <b-link
              :to="{ name: 'staff-edit', params: { staffId: data.item._id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.teamName }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            :to="{ name: 'staff-edit', params: { staffId: data.item._id } }"
            size="sm"
          >
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">แก้ไข</span>
          </b-button>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ paginationMeta.from }} to {{ paginationMeta.to }} of {{ paginationMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-dropdown
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :text="String(pageSize)"
              right
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="pageSizeOption in pageSizeOptions"
                :key="String(pageSizeOption)"
                @click="pageSize=pageSizeOption"
              >
                {{ pageSizeOption }}
              </b-dropdown-item>
            </b-dropdown>

            <b-pagination
              v-model="currentPage"
              :total-rows="totalStaffs"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 ml-2"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BFormInput, BCol, BRow, BTable, BPagination, BLink, BBadge, BDropdown, BDropdownItem, BMedia, BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import useStaffList from '@/composables/useStaffList'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  setup() {
    return {
      ...useStaffList(),
      avatarText,
    }
  },
}
</script>

<style>

</style>
