import { ref, watch, computed } from '@vue/composition-api'
import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useStaffList() {
  // Use toast
  const toast = useToast()

  const refStaffListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user', sortable: true },
    { key: 'mobileNumber', sortable: true },
    { key: 'role', sortable: true },
    { key: 'actions' },
  ]
  const totalStaffs = ref(0)
  const currentPage = ref(1)
  const pageSize = ref(10)
  const pageSizeOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const paginationMeta = computed(() => {
    const localItemsCount = refStaffListTable.value ? refStaffListTable.value.localItems.length : 0
    return {
      from: pageSize.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (currentPage.value - 1) + localItemsCount,
      of: totalStaffs.value,
    }
  })

  const refetchData = () => {
    refStaffListTable.value.refresh()
  }

  watch([currentPage, pageSize], () => {
    refetchData()
  })

  const submitSearch = () => {
    refetchData()
  }

  const fetchStaffs = (ctx, callback) => {
    axios
      .post('/staffs/list', {
        search: searchQuery.value,
        page: currentPage.value,
        pageSize: pageSize.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { rows, total } = response.data

        callback(rows)
        totalStaffs.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching staffs list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchStaffs,
    submitSearch,
    tableColumns,
    pageSize,
    totalStaffs,
    paginationMeta,
    currentPage,
    pageSizeOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refStaffListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
  }
}
